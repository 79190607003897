<div class="page-container">
    <app-header></app-header>
  
    <div class="back-container">
      <div class="hero-container container ">
        <div class="row">
          <div class="data col-md-7 col-sm-12 ps-5">
            <h1>Bienvenido a nuestro portal de<br><span style="color: #20cbd4;font-weight: 600;">facturación en línea.</span></h1><br>
            <p>Aquí podrás generar y descargar tus facturas electrónicas<br>de manera rápida y sencilla.</p><br>
            <p-button class="fact-btn-raised" (onClick)="goToHome()" [raised]="true" label="Generar Factura de Punto de Venta"></p-button>
            <p-button class="faq-btn-modal d-block mt-3" 
            (onClick)="showDialog('flinea-modal')" 
            [outlined]="true"
            label="¿Compraste en el sitio web? Ver más">
          </p-button>
          </div>
          <div class="col-lg-5 col-md-5 image-container">
            <img src="../../../assets/demo/images/ill-instructions.webp" width="400">
            <span style="color:#9b9b9b;">v. 1.2.0</span>
          </div>
        
        </div>
      </div>
    </div>
  
    <div class="layout-container container">
      
      <div class="info-grid mt-5">
        <div class="info-card">
          <img src="../../../assets/demo/images/instructions/icn-ticket.webp" alt="Requisitos" class="info-image">
          <div class="info-text">
            <h3>Nota de compra</h3>
            <ul>
              <li>Número de nota</li>
              <li>Sucursal</li>
              <li>Fecha de la compra</li>
              <li>Total de la compra</li>
            </ul>
          </div>
        </div>
        <div class="info-card">
          <img src="../../../assets/demo/images/instructions/icn-csf.webp" alt="Plazo" class="info-image">
          <div class="info-text">
            <h3>Datos fiscales</h3>
            <ul>
              <li>RFC</li>
              <li>Régimen fiscal</li>
              <li>Razón social</li>
              <li>Código Postal</li>
            </ul>
            <a class="faq-btn-modal d-block mt-3" (click)="showDialog('sat-modal')" role="button">Ver más</a>
          </div>
        </div>
        <div class="info-card">
          <img src="../../../assets/demo/images/instructions/icn-download.webp" alt="Modificación" class="info-image">
          <div class="info-text">
            <h3>Genera y descarga tu factura</h3>
          </div>
        </div>    
        <div class="support-container" hidden>
          <p>Para cualquier aclaración contáctanos en <a href="mailto:facturacion fraiche.com.mx">facturacion fraiche.com.mx</a> o <a href="tel:+5510101010">5510101010</a></p>
        </div>  
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  
  <p-dialog
    class="flinea-modal"
    header="Instrucciones para solicitar tu factura de compras en sitio web"
    [modal]="true"
    [(visible)]="display_flinea_modal"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" 
    [style]="{width: '50vw'}"
    [draggable]="false"
    [dismissableMask]="true"
  >
    <div>
      <p><b>Método de Pago y Facturación:</b><br>
      Al pagar tu pedido, selecciona la opción "Deseo facturar" en la sección de Método de Pago y Facturación.
      Llena el formulario con tus datos fiscales para generar la factura. Si es la primera vez que solicitas una factura con nosotros, por favor envía tu Constancia de Situación Fiscal actualizada al correo <a href="mailto:facturacion&#64;fraiche.com.mx">facturacion&#64;fraiche.com.mx</a> en un plazo no mayor a 24 horas contadas a partir del pago.</p>
  
      <p><b>Importante:</b><br></p>
      <ol>
        <li>- Si no envías la Constancia de Situación Fiscal dentro del plazo establecido, no será posible generar la factura.</li>
        <li>- Si no solicitas la factura dentro del tiempo indicado, se generará con un RFC genérico.</li>
      </ol>
  
      <br><p><b>Envío de la Factura:</b><br>
      El archivo PDF y XML de tu factura será enviado al correo electrónico que registraste cuando te diste de alta en nuestro sitio web.</p>
  
      <p><b>Restricción:</b><br>
      Recuerda que no es posible solicitar la factura si tu pedido ya fue enviado.
      </p>
    </div>
  </p-dialog>
  
  <p-dialog
    class="sat-modal"
    header="Nota"
    [modal]="true"
    [(visible)]="display_sat_modal"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" 
    [style]="{width: '50vw'}"
    [draggable]="false"
    [dismissableMask]="true"
  >
    <div>
      <p>Asegúrate de que el número de Registro Federal de Contribuyentes (RFC), tu nombre completo o razón social, y tu domicilio fiscal coincidan y estén vigentes con los registrados ante el Servicio de Administración Tributaria (SAT) y con los de tu Constancia de Situación Fiscal (CSF). 
      Te recomendamos validar los datos de tu Constancia de Situación Fiscal en el portal <a href="https://agsc.siat.sat.gob.mx/PTSC/ValidaRFC/index.jsf">https://agsc.siat.sat.gob.mx/PTSC/ValidaRFC/index.jsf</a>.</p>
    </div>
  </p-dialog>
  
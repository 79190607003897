import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem, MessageService } from 'primeng/api';
import { FacturacionService } from '../../core/services/facturacion.service';
import { datosNotaResponse } from '../../core/Interfaces/datos-nota-response';
import { tipoClienteSustituto } from '../../core/Interfaces/tipo-cliente-sustitutos';
import { DataRFCComponent } from '../data-rfc/data-rfc.component';
import { DataNotaComponent } from '../data-nota/data-nota.component';
import { DatosNotaRequest } from '../../core/Interfaces/datos-nota-request';
import {
  DatosFacturaRequest,
  DatosFacturaResponse,
} from '../../core/Interfaces/datos-factura';
import { DatosClienteRFCResponse } from '../../core/Interfaces/datos-cliente-rfc';
import { DatosFacturaExistente } from '../../core/Interfaces/datos-factura-existente';
import { Message } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



imports: [EventEmitter, Output];

@Component({
  selector: 'app-containe-factura',
  templateUrl: './container-factura.component.html',
  styleUrl: './container-factura.component.scss',
  providers: [DialogService, MessageService],
})
export class ContainerFactura implements OnDestroy, OnInit {
  items!: MenuItem[];
  messages!: Message[];
  displayWait: boolean = false;
  selectedFRC: any | undefined = { rfc: '', name: '', zipCode: 0 };
  datosNota!: DatosNotaRequest; //nota = new nota("x", "x", new Date, 0);
  enableNext: boolean = false;
  enableNext_1: boolean = true;
  DatosNotasResponse!: datosNotaResponse;
  ref: DynamicDialogRef | undefined;
  iTipoClienteSustituto!: tipoClienteSustituto;
  datosClienteRFC: DatosClienteRFCResponse = {
    Nombre: '',
    RFC: '',
    CP: '',
    Email: '',
    IdCliente: '',
    IdSustituto: '',
    RegimenFiscalSAT: '',
  };
  sFacturaExistente!: string;
  @ViewChild(DataRFCComponent, { static: false }) DataRFCComponent: any;
  @ViewChild(DataNotaComponent, { static: false }) DataNotaComponent: any;
  datosFacturaRequest!: DatosFacturaRequest;
  datosFacturaResponse!: DatosFacturaResponse;
  stepStepper: number = 0;
  facturaFile!: DatosFacturaExistente;
  sRFC!: string;
  sPDF!: string;
  sXML!: string;
  @Output() activeIndexChange: EventEmitter<any> = new EventEmitter();
  userForm!: FormGroup;
  bFran !: boolean;

  constructor(
    public dialogService: DialogService,
    public messageService: MessageService,
    private service: FacturacionService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.userForm = this.fb.group({
      comNota: this.fb.group({
        txtNota: [null, Validators.compose([Validators.required])],
      }),
    });
  }

  ngOnDestroy(): void {}

  changeStatusNext(val: boolean) {
    this.enableNext = val;
  }

  changeStatusNext_1(val: boolean) {
    this.enableNext_1 = val;
  }

  getDataRFC(val: DatosClienteRFCResponse) {
    this.datosClienteRFC = val;
  }

  getDataNotaChild(val: DatosNotaRequest) {
    this.datosNota = val;
  }

  async validateNota() {
    let data = await this.service.getValidateNota(this.datosNota);
    this.DatosNotasResponse = data;
  }

  async NextStep(call: any) {
    try {

        /* Se obtiene informacion de la Nota */
      this.datosNota = this.DataNotaComponent.sentDataNotaParent();
      //if (this.datosNota.PuntoVenta.substring(0, 1).toUpperCase() == 'K') this.bFran =true;
      //else this.bFran =false;

      this.displayWait = true;

      if (
        this.datosNota.Nota != '' &&
        this.datosNota.PuntoVenta != '' &&
        this.datosNota.FechaOperacion &&
        this.datosNota.MontoTotal
      ) {
        await this.validateNota();

        this.bFran = this.DatosNotasResponse.Data.Franquicia;

        if (this.DatosNotasResponse) {
          switch (this.DatosNotasResponse.Message.split(':')[0]) {
            case 'VD':
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'NOTA NO VALIDA POR DEVOLUCIÓN',
              });
              return;

            case 'VF':
              this.sFacturaExistente =
                this.DatosNotasResponse.Message.split(':')[1].trim();
              await this.getExistingFactura(
                this.DatosNotasResponse.Message.split(':')[1].trim()
              );
              this.stepStepper = 2;
              this.displayWait = false;
              call.emit();
              return;

            case 'VN':
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'VERIFIQUE LA INFORMACION NOTA NO ENCONTRADA',
              });
              return;
          }

          if (this.DatosNotasResponse.Result != 0) {

            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'VERIFIQUE LA INFORMACIÓN, DATOS NO VALIDOS',
            });

            if (this.DatosNotasResponse.Message = 'FechaFueraRango'){

              this.messageService.add({
                severity: 'error',
                summary: 'Alerta',
                detail: 'LA NOTA NO PERTENECE AL MES EN CURSO',
              });

            }
            

            
            
            return;
          }
          if (this.DatosNotasResponse.Data.NotaValida == true) call.emit();
        }
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'VALIDAR INFORMACIÓN',
        });
      }
      this.displayWait = false;
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error inesperado consulte al Administrador',
      });
    } finally {
      this.displayWait = false;
    }
  }

  async NextFinal(call: any): Promise<void> {
    try {
      this.displayWait = true;
      this.datosFacturaRequest = this.DataRFCComponent.sentDataRFCParent();
 
      let idCliente = '';
      let idSustituto = '';
      //Cliente ZNOTA
      if (this.DatosNotasResponse.Data.IdCliente.trimEnd().trimStart() == 'ZNOTA') {
        if (this.datosClienteRFC.IdCliente == '') {
          idCliente = '';
          idSustituto = '0';
        }

        if (this.datosClienteRFC.IdCliente.trimEnd().trimStart() != '') {
          idCliente = this.datosClienteRFC.IdCliente;
          idSustituto = this.datosClienteRFC.IdSustituto;
        }
      } else {
        // Cliente Distribuidor

        idCliente = this.datosFacturaRequest.IdCliente;
        idSustituto = this.datosFacturaRequest.IdSustituto;
      }

      this.datosFacturaRequest.IdCliente = idCliente;
      this.datosFacturaRequest.IdGiro =
        this.DatosNotasResponse.Data.IdGiro.toString();
      this.datosFacturaRequest.Nota = this.datosNota;
      this.datosFacturaRequest.IdSustituto = idSustituto;
      this.iTipoClienteSustituto;

      if (!this.validateDatosRFC(this.datosFacturaRequest)) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Advertencia',
          detail: 'VALIDAR INFORMACIÓN',
        });
        this.displayWait = false;
        return;
      }

      if(!this.datosFacturaRequest.AvisoPrivacidad){
        this.messageService.add({
          severity: 'warn',
          summary: 'Advertencia',
          detail: 'Aceptar Aviso de Privacidad para continuar',
        });
        this.displayWait = false;
        return;
      }



      if (this.DatosNotasResponse.Data.Franquicia == true)
        this.datosFacturaRequest.CorreoFran =
          this.DatosNotasResponse.Data.CorreoFran;

       let msgResponse = await this.generateFactura()
        
       switch (msgResponse) {
         case 'FacturaOK':
           call.emit();
           break;
         case 'ErrorNoControlado':
           this.messageService.add({
             severity: 'error',
             summary: 'Error',
             detail: 'LA FACTURA NO SE PUDO GENERAR',
           });
           break;
         case 'CFDI':
           this.messageService.add({
             severity: 'error',
             summary: 'Error',
             detail: 'VERIFIQUE SUS DATOS FISCALES',
           });
           break;
          case "The specified condition was not met for 'Email'." :
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'VALIDE SU CORREO',
            });
       }


      

    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Detail',
        detail: 'Error inesperado consulte al Administrador',
      });
    } finally {
      this.displayWait = false;
    }
  }

  changeStep(nextIndex: any) {
    this.stepStepper = this.stepStepper = 2 ? this.stepStepper : nextIndex;
  }

  async getExistingFactura(nota: string) {
    let notaA: DatosNotaRequest;
    notaA = this.datosNota;
    notaA.Nota = nota;
    this.facturaFile = await this.service.getExistingFactura(notaA);
    this.sRFC = this.facturaFile.Data.zipXml.replace('getDocument', '');
  }

  async generateFactura(): Promise<string> {
    this.facturaFile = await this.service.generateFactura(
      this.datosFacturaRequest
    );

    this.sRFC =
      this.datosFacturaRequest.RFC + '_' + this.datosFacturaRequest.Nota.Nota;

    return this.facturaFile.Message;
  }

  crearZipFacturas(): void {
    //this.sPDF = this.datosFacturaResponse.pdf;
    let byteArray = new Uint8Array(
      atob(this.facturaFile.Data.zipFactura)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    let file = new Blob([byteArray], { type: 'application/zip' });
    let fileURL = URL.createObjectURL(file);
    let fileName = this.datosClienteRFC.RFC + '_' + this.datosNota.Nota;
    let link = document.createElement('a');
    link.download = fileName;
    link.target = '_blank';
    link.href = fileURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  validateDatosRFC(datosFacturaRequest: DatosFacturaRequest): boolean {
    let bResult: boolean = true;

    if (datosFacturaRequest.IdGiro == '' || !datosFacturaRequest.IdGiro)
      bResult = false;

    if (datosFacturaRequest.RFC == '' || !datosFacturaRequest.RFC)
      bResult = false;

    if (datosFacturaRequest.CP == '' || !datosFacturaRequest.CP)
      bResult = false;

    if (
      datosFacturaRequest.RegimenFiscal == '' ||
      !datosFacturaRequest.RegimenFiscal
    )
      bResult = false;

    if (datosFacturaRequest.UsoCFDI == '' || !datosFacturaRequest.UsoCFDI)
      bResult = false;

    var expresion =
      /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      datosFacturaRequest.eMailEnable &&
      !expresion.test(datosFacturaRequest.Email) &&
      !datosFacturaRequest.Email
    ) {
      bResult = false;
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'VERIFIQUE SU CORREO ELECTRONICO',
      });
    }

    return bResult;
  }

  backStep(call: any) {
    this.stepStepper = 0;
    call.emit();
  }

  async getDataFranquicia(): Promise<void> {}
}
